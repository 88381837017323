/* eslint-disable no-restricted-syntax */
const version = "1.0.10";

const themes = {
  unisot: "UNISOT",
  abendum: "Abendum",
};

const theme = themes.abendum;

const tokenStorage = {
  cookie: "COOKIE",
  localStorage: "LOCAL_STORAGE",
};
let tokenStorageType = tokenStorage.localStorage;

const paymentProvider = {
  stripe: "STRIPE",
  handCash: "HANDCASH",
};
const serviceSubscriptionPaymentMethods = {
  invoice: "INVOICE",
  creditCard: "CREDIT CARD",
  wallet: "WALLET",
};

const stripePublicKey = "pk_live_yB6kZwiETXWWLVV2UReqcWkP";

const showDID = false;
const productName = "UNISOT SDC";
const logoText = "UNISOT";
const logoURL = "https://unisot.com";
const activePricingPerOperation = true;

const production = true;
const testDockerBuild = false;
const validateUserDocuments = true;
const useTestnet = false;
let showRegisterPage = true;
const TRIAL_ACCOUNT_EXPIRY_TIME_SEC = 2592000;
const minDocumentPrice = 0.35;
const minSignaturePrice = 0.35;
const minReadingPrice = 0.1;

let instanceId = "5f0e20d6dcf33c70405bc1dc";
let idFrontendURL = "http://localhost:3000";
// let idBackendURL = "http://localhost:4000";
let idBackendURL = "https://testid.unisot.id";
let serviceFrontendURL = "http://localhost:3000";
// let serviceBackendURL = "http://localhost:5000";
let serviceBackendURL = "https://testsdc.unisot.id";
let blockchainInfoURL = "https://whatsonchain.com/tx/{{transactionId}}";

if (!production) {
  idFrontendURL = "https://test-signonchain.netlify.app";
  serviceFrontendURL = "https://test-signonchain.netlify.app";
  // idBackendURL = "https://testid.unisot.id";
  serviceBackendURL = "https://testsdc.unisot.id";
  showRegisterPage = false;
}
if (production) {
  instanceId = "6183e9c3094b962df7ab461d";
  idFrontendURL = "https://sign.signonchain.id";
  serviceFrontendURL = "https://sign.signonchain.id";
  idBackendURL = "https://idproduction.unisot.id";
  serviceBackendURL = "https://sdcproduction.unisot.id";
}
if (testDockerBuild) {
  idBackendURL = "http://localhost:3500";
  serviceBackendURL = "http://localhost:3000";
  tokenStorageType = tokenStorage.localStorage;
}
if (useTestnet) {
  blockchainInfoURL = "https://test.whatsonchain.com/tx/{{transactionId}}";
}

const adLoginURL = `${idBackendURL}/api/users/ad/login`;

const storageFormat = "base64";
const messageTimeout = 6000;
const queryBackendInterval = 500;

const getFormattedUserName = (userId, userProfile) =>
  userId !== userProfile._id ? `for ${userProfile.name}` : "";

const methods = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  patch: "PATCH",
};

const contentTypes = {
  json: "application/json",
  formData: "form-data",
  searchParams: "search-parameters",
  fileDownload: "file-download",
};

const urlType = {
  id: "ID SERVICE",
  service: "BACKEND SERVICE",
};

const jobStatus = {
  failed: "FAILED",
  failedBlockchain: "FAILED_BLOCKCHAIN",
  done: "DONE",
  completed: "COMPLETED",
  inProgress: "IN PROGRESS",
};

const jobTransactionStatus = {
  prepared: "PREPARED",
  written: "WRITTEN",
};

const packageStatus = {
  sent: "SENT",
  read: "READ",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const shareItemStatus = {
  pending: "PENDING",
  shared: "SHARED",
  failed: "FAILED",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const documentContainerStatus = {
  stored: "STORED",
  deleted: "DELETED",
  expired: "EXPIRED",
};

const packageState = {
  pending: "PENDING",
  accepted: "ACCEPTED",
  rejected: "REJECTED",
};

const packageAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const shareItemAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const documentContainerAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const signatureAccess = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const packageStorageService = {
  cassandra: "CASSANDRA",
};

const shareItemStorageService = {
  cassandra: "CASSANDRA",
  // s3: "S3"
};

const shareItemDataType = {
  json: "application/json",
  xml: "text/xml",
  string: "string",
};

const parentTypes = {
  package: "PACKAGE",
  shareItem: "SHARE_ITEM",
  container: "CONTAINER",
  metadata: "METADATA",
};

const metadataTypes = {
  json: "application/json",
  xml: "text/xml",
  string: "string",
};

const accessRightsTypes = {
  read: "READ",
  sign: "SIGN",
};

const accessRightsStatus = {
  active: "ACTIVE",
  expired: "EXPIRED",
};

const serviceTypes = {
  sdc: "SDC",
  ubn: "UBN",
  topUp: "TOP UP",
  soc: "SOC",
};

const keyTypes = {
  master: "MASTER",
};

const documentTypes = {
  avatar: "AVATAR",
  logo: "LOGO",
  userId: "USER ID",
  driversLicense: "DRIVERS LICENSE",
  companyDocuments: "COMPANY DOCUMENTS",
  signature: "SIGNATURE",
};

const documentCriteria = {
  documentType: "byType",
  documentId: "byId",
};

const subscriptionTypes = {
  sdc: "SDC",
  ubn: "UBN",
  uie: "UIE",
  paymail: "PAYMAIL",
};

const subscriptionPlans = {
  operation: "PER OPERATION",
  monthly: "MONTHLY",
  yearly: "YEARLY",
};

const subscriptionPaymentMethods = {
  invoice: "INVOICE",
};

const subscriptionStatuses = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  cancelled: "CANCELLED",
};

const serviceSubscriptionPlans = {
  operation: "PER OPERATION",
  monthly: "MONTHLY",
  yearly: "YEARLY",
};

const organizationRoleTypes = {
  admin: "ADMIN",
  employee: "EMPLOYEE",
  partner: "PARTNER",
  registered: "REGISTERED",
};

const disableNotifications = "false";

const defaultRegistrationServices = [
  {
    serviceType: serviceTypes.sdc,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [
      {
        name: serviceTypes.sdc,
        generateKeys: true,
      },
    ],
  },
  {
    serviceType: serviceTypes.topUp,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [],
  },
  {
    serviceType: serviceTypes.soc,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [],
  },
];

const defaultRegistrationRoles = ["id_user", "sdc_user"];

const initState = {
  "Product name (optional)": {
    type: metadataTypes.string,
    value: "",
    translation: "product-name-optional",
  },
  "Product quantity (optional)": {
    type: metadataTypes.string,
    value: "",
    translation: "product-quantity-optional",
  },
  "Amount Excluding VAT": {
    type: metadataTypes.string,
    value: "",
    translation: "amount-excluding-vat",
  },
  "Amount Including VAT": {
    type: metadataTypes.string,
    value: "",
    translation: "amount-including-vat",
  },
  "VAT Amount": {
    type: metadataTypes.string,
    value: "",
    translation: "vat-amount",
  },
  Currency: {
    type: metadataTypes.string,
    value: "",
    translation: "currency",
  },
  "Invoice number": {
    type: metadataTypes.string,
    value: "",
    translation: "invoice-number",
  },
  "Invoice date": {
    type: metadataTypes.string,
    value: "",
    translation: "invoice-date",
  },
  "Your organization number": {
    type: metadataTypes.string,
    value: "",
    translation: "your-organization-number",
  },
  "Customer organization number": {
    type: metadataTypes.string,
    value: "",
    translation: "customer-organization-number",
  },
  "Supplier organization number": {
    type: metadataTypes.string,
    value: "",
    translation: "supplier-organization-number",
  },
  "Text/xml (optional)": {
    type: metadataTypes.xml,
    value: "",
    translation: "text-xml-optional",
  },
  "JSON (optional)": {
    type: metadataTypes.json,
    value: "",
    translation: "json-optional",
  },
  "Other (optional)": {
    type: metadataTypes.string,
    value: "",
    translation: "other-optional",
  },
};

const endpoints = {
  readUser: {
    method: methods.get,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  addOrganizationUser: {
    method: methods.post,
    path: "users/organizations/user",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteOrganizationUser: {
    method: methods.delete,
    path: "users/organizations/user",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getPublicSystemConfig: {
    method: methods.get,
    path: "public/config",
    status: 200,
    urlType: urlType.id,
  },
  getPublicKey: {
    method: methods.get,
    path: "users/keys",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getAllUsers: {
    method: methods.get,
    path: "users/keys/sdc",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPublicShareUser: {
    method: methods.get,
    path: "users/publicShare",
    status: 200,
    urlType: urlType.id,
  },
  getUserDocuments: {
    method: methods.get,
    path: "users/documents",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  readUserDocument: {
    method: methods.get,
    path: "users/documents/{{documentCriteria}}/base64",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.id,
  },
  downloadUserDocument: {
    method: methods.get,
    path: "users/documents/{{documentCriteria}}/raw",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
    urlType: urlType.id,
  },
  getExhangeRates: {
    method: methods.get,
    path: "pricing/currencyExchangeRate",
    status: 200,
    urlType: urlType.service,
  },
  monitorHealth: {
    method: methods.get,
    path: "monitor/health",
    status: 200,
    urlType: urlType.service,
  },
  getUserJobs: {
    method: methods.get,
    path: "job/filter",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getLatestJobStatus: {
    method: methods.get,
    path: "job/{{jobId}}/latest",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getLatestPublicJobStatus: {
    method: methods.get,
    path: "package/contents/job/{{jobId}}/latest",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  uploadFile: {
    method: methods.post,
    path: "storage/store/file",
    status: 200,
    contentType: contentTypes.formData,
    urlType: urlType.service,
  },
  uploadFileBase64: {
    method: methods.post,
    urlType: urlType.service,
    path: `storage/store/base64`,
    status: 200,
    contentType: contentTypes.json,
  },
  shareFile: {
    method: methods.post,
    path: "storage/share/file",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteFile: {
    method: methods.delete,
    path: "storage/delete/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getSendData: {
    method: methods.get,
    path: "storage/send/files",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getReceivedData: {
    method: methods.get,
    path: "storage/received/files",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  prepareDownloadFile: {
    method: methods.get,
    path: "storage/prepare/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  downloadFile: {
    method: methods.get,
    path: "storage/download/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  prepareDownloadReport: {
    method: methods.post,
    path: "signature/report",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  prepareDownloadFormReport: {
    method: methods.post,
    path: "signature/form/report",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  downloadReport: {
    method: methods.get,
    path: "signature/download/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  getPackageDetails: {
    method: methods.get,
    path: "storage/details/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPackageSignature: {
    method: methods.get,
    path: "storage/verify/file",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addPackageComment: {
    method: methods.patch,
    path: "storage/store/comment",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deletePackageComment: {
    method: methods.delete,
    path: "storage/store/comment",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updatePackageParentId: {
    method: methods.patch,
    path: "storage/update/parentId",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updateGroupName: {
    method: methods.patch,
    path: "storage/update/groupName",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageShareable: {
    method: methods.patch,
    path: "storage/update/shareable",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageState: {
    method: methods.patch,
    path: "storage/update/state",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updatePackageTTL: {
    method: methods.patch,
    path: "storage/update/ttl",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  getPackageQrCode: {
    method: methods.get,
    path: "storage/qrCode",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPublicPackageDetails: {
    method: methods.get,
    path: "package/details/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  getPublicPackageSignature: {
    method: methods.get,
    path: "package/verify/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  preparePublicDownloadPackageFile: {
    method: methods.get,
    path: "package/contents/{{packageId}}/{{publicShareSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.service,
  },
  downloadPublicFile: {
    method: methods.get,
    path: "package/contents/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.service,
  },
  getSharedItems: {
    method: methods.get,
    path: "share/shared/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getSharedItemsCount: {
    method: methods.get,
    path: "share/shared/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getShareItem: {
    method: methods.get,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.service,
  },
  addShareItem: {
    method: methods.post,
    path: "share",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteShareItem: {
    method: methods.delete,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.service,
  },
  updateShareItem: {
    method: methods.patch,
    path: "share/{{shareId}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.service,
  },
  downloadShareItem: {
    method: methods.get,
    path: "share/{{shareId}}/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
  },
  addReminder: {
    method: methods.post,
    path: "reminders",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteReminder: {
    method: methods.delete,
    path: "reminders",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updateReminder: {
    method: methods.patch,
    path: "reminders",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updateFlags: {
    method: methods.patch,
    path: "storage/update/flags",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  getDocumentContainers: {
    method: methods.get,
    path: "documentContainer/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainersCount: {
    method: methods.get,
    path: "documentContainer/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainer: {
    method: methods.get,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getDocumentContainerQrCode: {
    method: methods.get,
    path: "documentContainer/qrCode",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addDocumentContainer: {
    method: methods.post,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteDocumentContainer: {
    method: methods.delete,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  updateDocumentContainer: {
    method: methods.patch,
    path: "documentContainer",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  addDocumentContainerComment: {
    method: methods.patch,
    path: "documentContainer/comment",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteDocumentContainerComment: {
    method: methods.delete,
    path: "documentContainer/comment",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  calculateMetadataStatistics: {
    method: methods.post,
    path: "metadata/statistics",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  calculateSignatureStatistics: {
    method: methods.post,
    path: "signature/statistics",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  addMetadata: {
    method: methods.post,
    path: "metadata",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updateMetadata: {
    method: methods.patch,
    path: "metadata",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteMetadata: {
    method: methods.delete,
    path: "metadata",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  downloadMetadata: {
    method: methods.get,
    path: "metadata/{{metadataId}}/file",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
  },
  addAccessRights: {
    method: methods.post,
    path: "accessRights",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  inviteAccessRights: {
    method: methods.post,
    path: "accessRights/invite",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteAccessRights: {
    method: methods.delete,
    path: "accessRights",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  addSignature: {
    method: methods.post,
    path: "signature",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  updateSignature: {
    method: methods.patch,
    path: "signature",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  deleteSignature: {
    method: methods.delete,
    path: "signature",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  verifySignature: {
    method: methods.get,
    path: "signature/verify",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPricingPlans: {
    method: methods.get,
    path: "users/pricingPlans",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getCurrencyExchangeRate: {
    method: methods.get,
    path: "pricing/currencyExchangeRate",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getPricingLists: {
    method: methods.get,
    path: "users/superuser/pricingPlans/pricingLists",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  requestPricingPlanUpdate: {
    method: methods.patch,
    path: "users/profile/requestPricingPlan",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserServices: {
    method: methods.get,
    path: "users/services",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPricingPerOperation: {
    method: methods.get,
    path: "pricing",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  loginUser: {
    method: methods.post,
    path: "users/login",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  logoutUser: {
    method: methods.post,
    path: "users/logout",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  logoutUserAllSessions: {
    method: methods.post,
    path: "users/logoutAll",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  generate2FASecret: {
    method: methods.patch,
    path: "users/profile/generate2FASecret",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  enable2FA: {
    method: methods.patch,
    path: "users/profile/enable2FA",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  disable2FA: {
    method: methods.patch,
    path: "users/profile/disable2FA",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUser: {
    method: methods.delete,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertUserImage: {
    method: methods.post,
    path: "users/documents/image",
    status: 201,
    contentType: contentTypes.formData,
    urlType: urlType.id,
  },
  updateUser: {
    method: methods.patch,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  createUser: {
    method: methods.post,
    path: "users/profile",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  createUserSuperuser: {
    method: methods.post,
    path: "users/superuser/profile",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getAvailableOrganizations: {
    method: methods.get,
    path: "users/availableOrganizations",
    status: 200,
    urlType: urlType.id,
  },
  updateOrganizationSuperuser: {
    method: methods.patch,
    path: "users/superuser/organizations",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserDocument: {
    method: methods.delete,
    path: "users/documents/{{documentCriteria}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.id,
  },
  sendUserResetPasswordEmailLink: {
    method: methods.post,
    path: "users/resetPassword/sendLink",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  resetUserPassword: {
    method: methods.patch,
    path: "users/resetPassword/{{userId}}/{{passwordResetSecret}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.id,
  },
  sendUserValidateEmailLink: {
    method: methods.get,
    path: "users/validateEmail/sendLink",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  upsertUserFile: {
    method: methods.post,
    path: "users/documents/file",
    status: 201,
    contentType: contentTypes.formData,
    urlType: urlType.id,
  },
  userValidateEmail: {
    method: methods.get,
    path: "users/validateEmail/{{parentId}}/{{parentType}}/{{emailValidationSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.id,
  },
  requestUserDocumentsValidation: {
    method: methods.patch,
    path: "users/profile/requestDocumentsValidation",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getSendDataCount: {
    method: methods.get,
    path: "storage/send/files/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getAccessRights: {
    method: methods.get,
    path: "accessRights/entries",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getAccessRightsCount: {
    method: methods.get,
    path: "accessRights/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
  getUserBillings: {
    method: methods.get,
    path: "users/billings",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadBillingDocument: {
    method: methods.get,
    path: "users/billings/report",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  startBillingTopUpPaymentSessionId: {
    method: methods.post,
    path: "users/billings/payment/topUp/session",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserAuditLogs: {
    method: methods.get,
    path: "users/auditLogs",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadUserAuditLogs: {
    method: methods.get,
    path: "users/auditLogs",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  getUserExists: {
    method: methods.get,
    path: "users/keys/sdc/exists",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  createGuestUser: {
    method: methods.post,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  sendUserLoginMagicLink: {
    method: methods.post,
    path: "users/magicLink/sendLink",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  magicLinkLogin: {
    method: methods.post,
    path: "users/magicLink/{{userId}}/{{magicLinkSecret}}",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
    populate: true,
  },
  calcHashFileBase64: {
    method: methods.post,
    path: "calculate/hash/file/base64",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.service,
  },
  calcHashFile: {
    method: methods.post,
    path: "calculate/hash/file",
    status: 200,
    contentType: contentTypes.formData,
    urlType: urlType.service,
  },
  getUsersList: {
    method: methods.get,
    path: "accessRights/emailReceivers",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.service,
  },
};

const localStorageSessionTokenKey = "userIdToken";

const getRedirectUri = (
  uri,
  serviceType = undefined,
  endpointName = undefined,
  userId = undefined,
  superuser = false
) => {
  let redirectUri = uri;
  const queryParams = {};
  let paramSet = false;
  if ([serviceTypes.sdc, serviceTypes.ubn].includes(serviceType)) {
    redirectUri = uri.endsWith("/") ? uri : `${uri}/`;
    redirectUri = `${redirectUri}auth/redirect-page`;
    if (serviceType === serviceTypes.ubn && endpointName) {
      queryParams.name = endpointName;
      paramSet = true;
    }
  }
  if (superuser && userId) {
    queryParams.userId = userId;
    paramSet = true;
  }
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
      paramSet = true;
    }
  }
  if (paramSet) {
    const formattedQueryParams = new URLSearchParams(queryParams).toString();
    redirectUri = `${redirectUri}?${formattedQueryParams}`;
  }
  return redirectUri;
};
const formatPricing = (price, currency, text) => {
  const formattedPrice = price.toFixed(2) === "0.00" ? text : price.toFixed(2);
  return `${formattedPrice} ${currency}`;
};

const getSessionToken = () => {
  const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
  if (sessionToken) {
    return sessionToken;
  }
  throw new Error("Session token does not exist");
};
const getUserProfile = () => {
  const sessionToken = JSON.parse(localStorage.getItem("userProfile"));
  if (sessionToken) {
    return sessionToken;
  }
  throw new Error("Session token does not exist");
};

const storeSessionToken = (resp) => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.setItem(localStorageSessionTokenKey, resp.token);
    localStorage.setItem("userProfile", JSON.stringify(resp.user));
  }
};

const removeSessionToken = () => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.removeItem(localStorageSessionTokenKey);
    localStorage.removeItem("userProfile");
  }
};

const populatePath = (urlPath, params) => {
  let populatedPath = urlPath;
  const processingParameters = Object.entries(params);
  const paramsToProcess = {};
  for (const [key, value] of processingParameters) {
    const token = `{{${key}}}`;
    if (urlPath.includes(token)) {
      populatedPath = populatedPath.split(token).join(value);
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    populatedPath,
    paramsToProcess,
  };
};

const apiRequest = async (
  endpoint,
  signal = undefined,
  params = {},
  authorize = true,
  exportSessionToken = false
) => {
  const { method, path, status, populate } = endpoint;
  let populatedPath = path;
  let paramsToProcess = params;
  if (populate) {
    const pathPopulatingResult = populatePath(path, params);
    populatedPath = pathPopulatingResult.populatedPath;
    paramsToProcess = pathPopulatingResult.paramsToProcess;
  }

  // Construct API URL
  const baseURL = endpoint.urlType === urlType.id ? idBackendURL : serviceBackendURL;
  const url = new URL(`${baseURL}/api/${populatedPath}`);

  // Set method
  const request = {
    method,
  };
  if (signal) {
    request.signal = signal;
  }

  // Set headers
  const headers = {};
  if (endpoint.contentType) {
    if (endpoint.contentType === contentTypes.json) {
      headers["Content-Type"] = endpoint.contentType;
      request.body = JSON.stringify(paramsToProcess);
    } else if (endpoint.contentType === contentTypes.formData) {
      const formData = new FormData();
      for (const [key, value] of Object.entries(paramsToProcess)) {
        formData.append(key, value);
      }
      request.body = formData;
    } else if (
      endpoint.contentType === contentTypes.searchParams ||
      endpoint.contentType === contentTypes.fileDownload
    ) {
      url.search = new URLSearchParams(paramsToProcess).toString();
    }
  }

  if (tokenStorageType === tokenStorage.cookie) {
    request.credentials = "include";
  } else if (tokenStorageType === tokenStorage.localStorage) {
    if (authorize) {
      const sessionToken = getSessionToken();
      headers.authorization = `Bearer ${sessionToken}`;
    }
  }

  if (Object.keys(headers).length > 0) {
    request.headers = headers;
  }
  const response = await fetch(url, request);

  let responseData;
  if (response.status === status && endpoint.contentType === contentTypes.fileDownload) {
    responseData = await response.blob();
  } else {
    responseData = await response.json();
  }
  if (response.status !== status) {
    throw new Error(responseData.message);
  }
  if (exportSessionToken) {
    storeSessionToken(responseData);
  }
  return responseData;
};

const isUserValidated = (userProfile, validateSuperuser = false) => {
  if (validateSuperuser) return userProfile.superuser;
  return (
    !userProfile.blocked &&
    userProfile.validatedEmail &&
    (!validateUserDocuments || userProfile.validatedDocuments)
  );
};

const getRedirectPath = (redirectPath) => {
  const redirectUri = `${idFrontendURL}/auth/redirect-page`;
  const queryParams = {
    redirectPath,
  };
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
    }
  }
  const formattedQueryParams = new URLSearchParams(queryParams).toString();

  return `${redirectUri}?${formattedQueryParams}`;
};

const getUserProfileViewPath = () => getRedirectPath("/admin/user-page");

const getLockProfileViewPath = () => getRedirectPath("/auth/lock-screen-page");

const getUpdatePricingPlanPage = () => getRedirectPath("/admin/user-subscriptions");

const getPricingPerOperationActive = (userProfile) =>
  !!userProfile.subscriptions.find(
    (subscription) =>
      subscription.type === subscriptionTypes.sdc &&
      subscription.plan === subscriptionPlans.operation &&
      (subscription.status === subscriptionStatuses.active ||
        subscription.status === subscriptionStatuses.cancelled)
  );

const splitParamFields = (params, fields) => {
  const paramsFields = {};
  const paramsToProcess = {};
  const processingParameters = Object.entries(params);
  for (const [key, value] of processingParameters) {
    if (fields.includes(key)) {
      paramsFields[key] = value;
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    paramsFields,
    paramsToProcess,
  };
};

const loginRequired = async (signal, redirectNotValidated, validateSuperuser) => {
  let userProfile;
  try {
    userProfile = await apiRequest(endpoints.readUser, signal);
    const validated = isUserValidated(userProfile, validateSuperuser);
    if (redirectNotValidated && !validated) {
      // window.location.replace(`${serviceFrontendURL}/admin/user-page`);
      window.location.replace(getUserProfileViewPath());
    }
  } catch (e) {
    if (signal?.aborted) {
      console.error(e);
      return e;
    }
    // console.error(e);
    removeSessionToken();
    // //todo
    window.location.href = "/authentication/sign-in";
  }
  return userProfile;
};

const queryLatestJobStatus = async (endpoint, signal, params, authorize = true) => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((r) => setTimeout(r, queryBackendInterval));
  const responseData = await apiRequest(endpoint, signal, params, authorize);
  return responseData;
};

const ApiService = {
  stripePublicKey,
  paymentProvider,
  serviceSubscriptionPaymentMethods,
  activePricingPerOperation,
  production,
  instanceId,
  storeSessionToken,
  removeSessionToken,
  getRedirectUri,
  populatePath,
  getUserProfileViewPath,
  getLockProfileViewPath,
  getUpdatePricingPlanPage,
  blockchainInfoURL,
  idFrontendURL,
  serviceFrontendURL,
  storageFormat,
  productName,
  logoText,
  logoURL,
  messageTimeout,
  jobStatus,
  jobTransactionStatus,
  packageState,
  packageAccess,
  packageStatus,
  shareItemAccess,
  shareItemStatus,
  documentContainerStatus,
  documentContainerAccess,
  signatureAccess,
  packageStorageService,
  shareItemStorageService,
  shareItemDataType,
  parentTypes,
  metadataTypes,
  accessRightsTypes,
  accessRightsStatus,
  validateUserDocuments,
  documentTypes,
  documentCriteria,
  isUserValidated,
  getFormattedUserName,
  subscriptionTypes,
  subscriptionPlans,
  subscriptionPaymentMethods,
  subscriptionStatuses,
  getPricingPerOperationActive,
  defaultRegistrationServices,
  defaultRegistrationRoles,
  serviceSubscriptionPlans,
  organizationRoleTypes,
  initState,
  version,
  themes,
  theme,
  showRegisterPage,
  formatDateTime: (input) => {
    if (!input) {
      return "Forever";
    }
    return input.replace(/[TZ]/g, " ").trim();
  },
  getValidPackageStates: (user, sender, receiver, currentPackageState) => {
    if (sender === receiver) {
      return [];
    }
    if (currentPackageState === packageState.pending && user === receiver) {
      return [packageState.accepted, packageState.rejected];
    }
    if (currentPackageState === packageState.rejected && sender === user) {
      return [packageState.pending];
    }
    return [];
  },
  loginRequired: async (
    signal = undefined,
    redirectNotValidated = true,
    validateSuperuser = false
  ) => {
    const responseData = await loginRequired(signal, redirectNotValidated, validateSuperuser);
    return responseData;
  },
  readUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.readUser, signal, params);
    return responseData;
  },
  deleteOrganizationUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteOrganizationUser, signal, params);
    return responseData;
  },
  addOrganizationUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addOrganizationUser, signal, params);
    return responseData;
  },
  getPublicSystemConfig: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPublicSystemConfig, signal, params, false);
    return responseData;
  },
  getPublicKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPublicKey, signal, {
      deploymentId: instanceId,
      serviceType: serviceTypes.sdc,
      keyType: keyTypes.master,
      ...params,
    });
    if (responseData.length === 0) {
      throw new Error("User does not have registered key");
    }
    return responseData[0];
  },
  getAllUsers: async (
    params,
    signal = undefined,
    filterOnlyValidatedUsers = true,
    filterOutSpecialUsers = false,
    filterOutSuperUsers = false
  ) => {
    const responseData = await apiRequest(endpoints.getAllUsers, signal, {
      ...params,
      deploymentId: instanceId,
    });
    let filteredEntries = responseData;
    if (filterOnlyValidatedUsers) {
      filteredEntries = filteredEntries.filter((entry) => isUserValidated(entry.user));
    }
    if (filterOutSpecialUsers) {
      filteredEntries = filteredEntries.filter((entry) => !entry.user.special);
    }
    if (filterOutSuperUsers) {
      filteredEntries = filteredEntries.filter((entry) => !entry.user.superuser);
    }
    return filteredEntries;
  },
  getPublicShareUser: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(endpoints.getPublicShareUser, signal, params, false);
    responseData.user = responseData;
    return responseData;
  },
  getUserDocuments: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserDocuments, signal, params);
    return responseData;
  },
  readUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.readUserDocument, signal, params);
    return responseData;
  },
  downloadUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadUserDocument, signal, params);
    return responseData;
  },
  getExhangeRates: async (signal = undefined) => {
    const responseData = await apiRequest(endpoints.getExhangeRates, signal, false);
    return responseData;
  },
  monitorHealth: async (signal = undefined) => {
    const responseData = await apiRequest(endpoints.monitorHealth, signal, false);
    return responseData;
  },
  getUserJobs: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserJobs, signal, params);
    return responseData;
  },
  queryLatestJobStatus: async (params, signal = undefined) => {
    const responseData = await queryLatestJobStatus(endpoints.getLatestJobStatus, signal, params);
    return responseData;
  },
  queryLatestPublicDownloadJobStatus: async (params, signal = undefined) => {
    const responseData = await queryLatestJobStatus(
      endpoints.getLatestPublicJobStatus,
      signal,
      params,
      false
    );
    return responseData;
  },
  uploadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.uploadFile, signal, params);
    return responseData;
  },
  uploadFileBase64: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.uploadFileBase64, signal, params);
    return responseData;
  },
  shareFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.shareFile, signal, params);
    return responseData;
  },
  deleteFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteFile, signal, params);
    return responseData;
  },
  getSendData: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getSendData, signal, params);
    return responseData.filter(
      (data) => data.status !== packageStatus.deleted && data.status !== packageStatus.expired
    );
  },
  getReceivedData: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getReceivedData, signal, params);
    return responseData.filter(
      (data) => data.status !== packageStatus.deleted && data.status !== packageStatus.expired
    );
  },
  prepareDownloadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.prepareDownloadFile, signal, params);
    return responseData;
  },
  downloadFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadFile, signal, params);
    return responseData;
  },
  prepareDownloadReport: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.prepareDownloadReport, signal, params);
    return responseData;
  },
  prepareDownloadFormReport: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.prepareDownloadFormReport, signal, params);
    return responseData;
  },
  downloadReport: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadReport, signal, params);
    return responseData;
  },
  getPackageDetails: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPackageDetails, signal, params);
    return responseData;
  },
  getPackageSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPackageSignature, signal, params);
    return responseData;
  },
  addPackageComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addPackageComment, signal, params);
    return responseData;
  },
  deletePackageComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deletePackageComment, signal, params);
    return responseData;
  },
  updatePackageParentId: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updatePackageParentId, signal, params);
    return responseData;
  },
  updateGroupName: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateGroupName, signal, params);
    return responseData;
  },
  updatePackageShareable: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updatePackageShareable, signal, params);
    return responseData;
  },
  updatePackageState: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updatePackageState, signal, params);
    return responseData;
  },
  updatePackageTTL: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updatePackageTTL, signal, params);
    return responseData;
  },
  getPublicPackageDetails: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPublicPackageDetails, signal, params, false);
    return responseData;
  },
  getPublicPackageSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicPackageSignature,
      signal,
      params,
      false
    );
    return responseData;
  },
  preparePublicDownloadPackageFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.preparePublicDownloadPackageFile,
      signal,
      params,
      false
    );
    return responseData;
  },
  getPackageQrCode: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPackageQrCode, signal, params);
    return responseData;
  },
  downloadPublicFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadPublicFile, signal, params, false);
    return responseData;
  },
  getSharedItems: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getSharedItems, signal, params);
    return responseData.filter(
      (data) => data.status !== shareItemStatus.deleted && data.status !== shareItemStatus.expired
    );
  },
  getSharedItemsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getSharedItemsCount, signal, params);
    return responseData;
  },
  addShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addShareItem, signal, params);
    return responseData;
  },
  getShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getShareItem, signal, params);
    return responseData;
  },
  deleteShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteShareItem, signal, params);
    return responseData;
  },
  updateShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateShareItem, signal, params);
    return responseData;
  },
  downloadShareItem: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadShareItem, signal, params);
    return responseData;
  },
  addReminder: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addReminder, signal, params);
    return responseData;
  },
  deleteReminder: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteReminder, signal, params);
    return responseData;
  },
  updateReminder: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateReminder, signal, params);
    return responseData;
  },
  updateFlags: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateFlags, signal, params);
    return responseData;
  },
  getDocumentContainers: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getDocumentContainers, signal, params);
    return responseData.filter(
      (data) => data.status !== shareItemStatus.deleted && data.status !== shareItemStatus.expired
    );
  },
  getDocumentContainersCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getDocumentContainersCount, signal, params);
    return responseData;
  },
  getDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getDocumentContainer, signal, params);
    return responseData;
  },
  getDocumentContainerQrCode: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getDocumentContainerQrCode, signal, params);
    return responseData;
  },
  addDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addDocumentContainer, signal, params);
    return responseData;
  },
  deleteDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteDocumentContainer, signal, params);
    return responseData;
  },
  updateDocumentContainer: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateDocumentContainer, signal, params);
    return responseData;
  },
  addDocumentContainerComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addDocumentContainerComment, signal, params);
    return responseData;
  },
  deleteDocumentContainerComment: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteDocumentContainerComment, signal, params);
    return responseData;
  },
  addMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addMetadata, signal, params);
    return responseData;
  },
  updateMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateMetadata, signal, params);
    return responseData;
  },
  deleteMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteMetadata, signal, params);
    return responseData;
  },
  downloadMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadMetadata, signal, params);
    return responseData;
  },
  calculateMetadataStatistics: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.calculateMetadataStatistics, signal, params);
    return responseData;
  },
  calculateSignatureStatistics: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.calculateSignatureStatistics, signal, params);
    return responseData;
  },
  addAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addAccessRights, signal, params);
    return responseData;
  },
  inviteAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.inviteAccessRights, signal, params);
    return responseData;
  },
  deleteAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteAccessRights, signal, params);
    return responseData;
  },
  addSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addSignature, signal, params);
    return responseData;
  },
  updateSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateSignature, signal, params);
    return responseData;
  },
  deleteSignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteSignature, signal, params);
    return responseData;
  },
  verifySignature: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.verifySignature, signal, params);
    return responseData;
  },
  getPricingPlans: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPricingPlans, signal, params, false);
    return responseData;
  },
  getCurrencyExchangeRate: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getCurrencyExchangeRate, signal, params, false);
    return responseData;
  },
  getPricingLists: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPricingLists, signal, params);
    return responseData;
  },
  requestPricingPlanUpdate: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.requestPricingPlanUpdate, signal, params);
    return responseData;
  },
  getUserServices: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserServices, signal, params);
    return responseData;
  },
  getPricingPerOperation: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getPricingPerOperation, signal, params);
    return responseData;
  },
  loginUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.loginUser, signal, params, false, true);
    return responseData;
  },
  logoutUser: async (params, history, redirect = true, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, ["superuser"]);
    const responseData = await apiRequest(endpoints.logoutUser, signal, paramsToProcess);
    if (!paramsFields.superuser) {
      removeSessionToken();
      if (redirect) {
        history.push("/authentication/sign-in");
      }
    }
    return responseData;
  },
  logoutUserAllSessions: async (params, history, redirect = true, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, ["superuser"]);
    const responseData = await apiRequest(endpoints.logoutUserAllSessions, signal, paramsToProcess);
    if (!paramsFields.superuser) {
      localStorage.removeItem("folders");

      removeSessionToken();
      if (redirect) {
        history.push("/authentication/sign-in");
      }
    }
    return responseData;
  },
  generate2FASecret: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.generate2FASecret, signal, params);
    return responseData;
  },
  enable2FA: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.enable2FA, signal, params);
    return responseData;
  },
  disable2FA: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.disable2FA, signal, params);
    return responseData;
  },
  deleteUser: async (params, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, ["superuser"]);
    const responseData = await apiRequest(endpoints.deleteUser, signal, paramsToProcess);
    if (!paramsFields.superuser) {
      removeSessionToken();
    }
    return responseData;
  },
  upsertUserImage: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.upsertUserImage, signal, params);
    return responseData;
  },
  updateUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.updateUser, signal, params, true, false);
    return responseData;
  },
  createUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.createUser, signal, params, false, true);
    return responseData;
  },
  createUserSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.createUserSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  getAvailableOrganizations: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getAvailableOrganizations,
      signal,
      params,
      false
    );
    return responseData;
  },
  updateOrganizationSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateOrganizationSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  deleteUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteUserDocument, signal, params);
    return responseData;
  },
  sendUserResetPasswordEmailLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sendUserResetPasswordEmailLink,
      signal,
      params,
      false
    );
    return responseData;
  },
  resetUserPassword: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.resetUserPassword, signal, params, false);
    return responseData;
  },
  sendUserValidateEmailLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.sendUserValidateEmailLink, signal, params);
    return responseData;
  },
  upsertUserFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.upsertUserFile, signal, params);
    return responseData;
  },
  userValidateEmail: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.userValidateEmail, signal, params, false);
    return responseData;
  },
  requestUserDocumentsValidation: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.requestUserDocumentsValidation, signal, params);
    return responseData;
  },
  getSendDataCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getSendDataCount, signal, params);
    return responseData;
  },
  getAccessRights: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getAccessRights, signal, params);
    return responseData;
  },
  getAccessRightsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getAccessRightsCount, signal, params);
    return responseData;
  },
  getUserBillings: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserBillings, signal, params);
    return responseData;
  },
  downloadBillingDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadBillingDocument, signal, params);
    return responseData;
  },
  startBillingTopUpPaymentSessionId: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.startBillingTopUpPaymentSessionId,
      signal,
      params
    );
    return responseData;
  },
  getUserAuditLogs: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserAuditLogs, signal, params);
    return responseData;
  },
  downloadUserAuditLogs: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.downloadUserAuditLogs, signal, params);
    return responseData;
  },
  getUserExists: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUserExists, signal, params);
    return responseData;
  },
  createGuestUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.createGuestUser, signal, params);
    return responseData;
  },
  sendUserLoginMagicLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.sendUserLoginMagicLink, signal, params, false);
    return responseData;
  },
  magicLinkLogin: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.magicLinkLogin, signal, params, false, true);
    return responseData;
  },
  calcHashFileBase64: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.calcHashFileBase64, signal, params);
    return responseData;
  },
  calcHashFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.calcHashFile, signal, params);
    return responseData;
  },
  getUsersList: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getUsersList, signal, params);
    return responseData;
  },
  getSessionToken,
  getUserProfile,
  formatPricing,
  disableNotifications,
  TRIAL_ACCOUNT_EXPIRY_TIME_SEC,
  minDocumentPrice,
  minSignaturePrice,
  minReadingPrice,
  adLoginURL,
  localStorageSessionTokenKey,
  showDID,
};

export default ApiService;
